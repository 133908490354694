/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

$(".nav-item.right").click(function(event) {
    event.preventDefault();
    $('html, body').animate({
        scrollTop: $("#contact").offset().top
    }, 800);
});

$('.grid').masonry({
  // set itemSelector so .grid-sizer is not used in layout
  itemSelector: '.grid-item',
  // use element for option
  columnWidth: '.grid-sizer',
  horizontalOrder: true
});

$('.slider-hero').each(function( index ) {
  $(this).attr('hero-slider', index);

  $('.slider', this).on('init', function(){
    if ($(window).width() < 1200) {
       $('.slick-slide', this).width($(window).width()*0.75);
    } else {
       $('.slick-slide', this).width('900px'); 
    }
  });

  $('.slider', this).on('setPosition', function(){
    if ($(window).width() < 1200) {
       $('.slick-slide', this).width($(window).width()*0.75);
    } else {
       $('.slick-slide', this).width('900px'); 
    }
  });
  

$('.slider', this).on( 'beforeChange', function( event, slick, currentSlide, nextSlide ) {
  if ( nextSlide === 0 ) {

    setTimeout(function() {
      $( '[data-slick-index="' + slick.$slides.length + '"]' ).addClass( 'slick-center' ).siblings().removeClass( 'slick-center' );
      for (var i = slick.options.slidesToShow - 1; i >= 0; i--) {
        $( '[data-slick-index="' + i + '"]' ).addClass( 'slick-center' );
      }
    }, 10 );
  }
});


  $('.slider', this).slick({
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 400,
    draggable: true,
    slidesToShow: 1,
    variableWidth: true,
    cssEase: 'ease-in-out',
    focusOnSelect: true,
    swipeToSlide: true,
    dots: false,
  });
});



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    var feed = new Instafeed({
        get: 'user',
        accessToken: InstagramToken,
        limit: 6,
        template: '<div class="instafeed-item col-12 col-lg-6"><div class="instafeed-item-image" style="background-image:url({{image}});"></div></div>',
    });
    feed.run();

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
